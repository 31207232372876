import React from 'react';

import Props from './types';
import useStyles from './style';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/KeyboardArrowLeft';
import LastPageIcon from '@material-ui/icons/KeyboardArrowRight';

const TablePaginationRow: React.FC<Props> = ({
  totalNumOfItems,
  totalRowPerPage,
  rowTitle
}: Props) => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(0);
  const pageBreakByNum = 4;
  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setRowsPerPage(event.target.value as number);
  };

  const RowNumberArray = [];
  for (let i = 1; i < totalNumOfItems; i++) {
    i += pageBreakByNum;
    RowNumberArray.push(i);
  }

  return (
    <>
      <Box
        py={2}
        mb={{ xs: 1, sm: 1 }}
        flexGrow={1}
        gridGap={12}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box gridGap={12} display="flex" alignItems="center">
          {rowTitle}
          <FormControl variant="outlined" size="small" className={classes.formControl}>
            <Select value={rowsPerPage} onChange={handleChange}>
              {RowNumberArray.map((num, index) => (
                <MenuItem key={index} value={index}>
                  {num}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box>
          1-{totalRowPerPage} of {totalNumOfItems} items
          <IconButton>
            <FirstPageIcon />
          </IconButton>
          <IconButton>
            <LastPageIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default TablePaginationRow;
