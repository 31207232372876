import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dailogClass: {
    background: 'red'
  },
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: '100%'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  dialogActions: {
    marginBottom: theme.spacing(1),
    padding: 0,
    justifyContent: 'flex-start'
  }
}));
