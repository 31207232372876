import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Groups from 'pages/Groups';

import Users from 'pages/Users';
import TrackModelRequest from 'pages/TrackModelRequest';
import ErrorPage from 'components/ErrorPage';

import routes from './routes';
import { DefaultAuthenticatedRoute } from './DefaultAuthenticatedRoute';
import { USE_SSO_LOGIN } from 'core/modules/auth.utils';
import Login from 'pages/Login';

const Router: React.FC = () => (
  <Switch>
    {!USE_SSO_LOGIN && (
      <Route path={routes.LOGIN}>
        <Login />
      </Route>
    )}
    <Route path={routes.ERROR_404}>
      <ErrorPage errorType="404" />
    </Route>
    <Route path={routes.ERROR_500}>
      <ErrorPage errorType="500" />
    </Route>
    <Route path={routes.ERROR_401}>
      <ErrorPage errorType="401" />
    </Route>
    <Route path={routes.ERROR_403}>
      <ErrorPage errorType="403" />
    </Route>
    <DefaultAuthenticatedRoute path={routes.GROUPS}>
      <Groups />
    </DefaultAuthenticatedRoute>
    <DefaultAuthenticatedRoute path={routes.USERS}>
      <Users />
    </DefaultAuthenticatedRoute>
    <DefaultAuthenticatedRoute path={routes.MODEL_REQUEST}>
      <TrackModelRequest />
    </DefaultAuthenticatedRoute>
    <DefaultAuthenticatedRoute path={routes.HOME}>
      <Groups />
    </DefaultAuthenticatedRoute>
  </Switch>
);
export default React.memo(Router);
