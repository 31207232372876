import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { fork, select, SelectEffect } from 'redux-saga/effects';
import { composeWithDevTools } from 'redux-devtools-extension';
import localizationReducer from 'localization/reducer';
import LoginPageReducer from 'pages/Login/duck';
import { LoginPageSaga } from 'pages/Login/duck';

import { all } from '@redux-saga/core/effects';

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

const rootReducer = combineReducers({
  localizationReducer,
  LoginPageReducer
});
function* rootSaga() {
  yield all([fork(LoginPageSaga)]);
}

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

export function selectState<T>(selector: (s: RootState) => T): SelectEffect {
  return select(selector);
}

export default store;
