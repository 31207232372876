import React from 'react';

import useLocal from 'localization';

import Props from './types';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import withStyles from '@material-ui/styles/withStyles';
import useStyles from './style';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

// date-fns
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

// TODO: These are dummy data. We will put real data while API configuration.
const modelsList = [
  {
    id: 1,
    modelname: 'CCO - Clear Channel Outdoor',
    requestedby: 'Jon',
    requestedon: '03/05/2020',
    targetdate: '02/02/2022',
    assignedto: 'John Doe',
    status: 'In Progress'
  },
  {
    id: 2,
    modelname: 'CCO - Clear Channel Outdoor',
    requestedby: 'Cersei',
    requestedon: '03/05/2020',
    targetdate: '02/02/2022',
    assignedto: 'Cersei',
    status: 'Success'
  },
  {
    id: 3,
    modelname: 'CCO - Clear Channel Outdoor',
    requestedby: 'Jaime',
    requestedon: '03/05/2020',
    targetdate: '02/02/2022',
    assignedto: 'Jaime',
    status: 'Success'
  },
  {
    id: 4,
    modelname: 'CCO - Clear Channel Outdoor',
    requestedby: 'Arya',
    requestedon: '03/05/2020',
    targetdate: '02/02/2022',
    assignedto: 'Arya',
    status: 'New'
  },
  {
    id: 5,
    modelname: 'CCO - Clear Channel Outdoor',
    requestedby: 'Daenerys',
    requestedon: '03/05/2020',
    targetdate: '02/02/2022',
    assignedto: 'Daenerys',
    status: 'Success'
  },
  {
    id: 6,
    modelname: 'CCO - Clear Channel Outdoor',
    requestedby: 'sny',
    requestedon: '03/05/2020',
    targetdate: '02/02/2021',
    assignedto: 'sny',
    status: 'New'
  },
  {
    id: 7,
    modelname: 'CCO - Clear Channel Outdoor',
    requestedby: 'Ferrara',
    requestedon: '03/05/2020',
    targetdate: '02/02/2021',
    assignedto: 'Ferrara',
    status: 'New'
  },
  {
    id: 8,
    modelname: 'CCO - Clear Channel Outdoor',
    requestedby: 'Rossini',
    requestedon: '03/05/2020',
    targetdate: '02/02/2021',
    assignedto: 'Rossini',
    status: 'Success'
  },
  {
    id: 9,
    modelname: 'CCO - Clear Channel Outdoor',
    requestedby: 'Harvey',
    requestedon: '03/05/2020',
    targetdate: '02/02/2021',
    assignedto: 'Daenerys',
    status: 'New'
  },
  {
    id: 10,
    modelname: 'CCO - Clear Channel Outdoor',
    requestedby: 'Harvey',
    requestedon: '03/05/2020',
    targetdate: '02/02/2021',
    assignedto: 'Daenerys',
    status: 'Success'
  },
  {
    id: 11,
    modelname: 'CCO - Clear Channel Outdoor',
    requestedby: 'Harvey',
    requestedon: '03/05/2020',
    targetdate: '02/02/2021',
    assignedto: 'Daenerys',
    status: 'Success'
  }
];

const statusValue: ['New', 'In Progress', 'Success'] = ['New', 'In Progress', 'Success'];

const TrackModelRequestTable: React.FC<Props> = ({ totalRowPerPage }: Props) => {
  const tableID = useLocal('table_heading_id');
  const tableModelName = useLocal('table_heading_modelname');
  const tableRequestedBy = useLocal('table_heading_requestedby');
  const tableRequestedOn = useLocal('table_heading_requestedon');
  const tableTargetDate = useLocal('table_heading_targetdate');
  const tableAssignedTo = useLocal('table_heading_assignedto');
  const tableStatus = useLocal('table_heading_status');
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState<number>(totalRowPerPage);
  const classes = useStyles();
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, modelsList.length - page * rowsPerPage);

  // TODO: These are dummy data. We will put real data while API configuration.
  const assignedTo = [
    'John Doe',
    'Cersei',
    'Jaime',
    'Arya',
    'Daenerys',
    'sny',
    'Ferrara',
    'Rossini'
  ];

  const statusMap: {
    New: string;
    Success: string;
    'In Progress': string;
  } = {
    New: classes.info,
    Success: classes.success,
    'In Progress': classes.error
  };
  const [models, setModels] = React.useState<
    {
      id: number;
      modelname: string;
      requestedby: string;
      requestedon: string;
      targetdate: string;
      assignedto: string;
      status: string;
    }[]
  >(modelsList);
  const handleAssignedtoChange = (
    event: React.ChangeEvent<{
      value: unknown;
    }>,
    id: number
  ) => {
    const data = models.map((model) =>
      model.id == id ? { ...model, assignedto: event.target.value as string } : model
    );
    setModels(data);
  };

  const handleStatusChange = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
    id: number
  ) => {
    const data = models.map((model) =>
      model.id == id ? { ...model, status: event.target.value as string } : model
    );
    setModels(data);
  };

  const handleDateChange = (date: Date | null, id: number) => {
    const year: number | undefined = date?.getFullYear();
    const month: number | undefined = (date?.getMonth() as number) + 1;
    const day: number | undefined = date?.getDate();
    const newDate = `${month}/${day}/${year}`;
    const data = models.map((model) =>
      model.id == id ? { ...model, targetdate: newDate } : model
    );
    setModels(data);
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      paddingLeft: 'theme.spacing(3)',
      background: theme.palette.bgGray.main,
      textTransform: 'uppercase'
    },
    body: {
      paddingLeft: 'theme.spacing(3)'
    }
  }))(TableCell);

  return (
    <TableContainer className={classes.tableHeight}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <StyledTableCell>{tableID}</StyledTableCell>
            <StyledTableCell>{tableModelName}</StyledTableCell>
            <StyledTableCell>{tableRequestedBy}</StyledTableCell>
            <StyledTableCell>{tableRequestedOn}</StyledTableCell>
            <StyledTableCell>{tableTargetDate}</StyledTableCell>
            <StyledTableCell className={classes.assignTH}>{tableAssignedTo}</StyledTableCell>
            <StyledTableCell className={classes.statusTH}>{tableStatus}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? models.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : models
          ).map((model) => (
            <TableRow key={model.id}>
              <TableCell scope="row">{model.id}</TableCell>
              <TableCell>{model.modelname}</TableCell>
              <TableCell>{model.requestedby}</TableCell>
              <TableCell>{model.requestedon}</TableCell>
              <TableCell>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    id="date-picker"
                    autoOk={true}
                    InputProps={{
                      disableUnderline: true
                    }}
                    style={{ maxWidth: '50%' }}
                    value={model.targetdate}
                    onChange={(event) => handleDateChange(event, model.id)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    name="targetdate"
                  />
                </MuiPickersUtilsProvider>
              </TableCell>
              <TableCell>
                <FormControl>
                  <Select
                    multiple={false}
                    value={model.assignedto}
                    disableUnderline={true}
                    onChange={(event) => handleAssignedtoChange(event, model.id)}
                  >
                    {assignedTo.map((name, index) => (
                      <MenuItem key={index} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <FormControl>
                  <Select
                    multiple={false}
                    value={model.status}
                    disableUnderline={true}
                    onChange={(event) => handleStatusChange(event, model.id)}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={() => (
                      <Chip
                        label={model.status}
                        className={
                          statusMap[
                            model.status as keyof {
                              New: string;
                              Success: string;
                              'In Progress': string;
                            }
                          ]
                        }
                      />
                    )}
                  >
                    {statusValue.map((name, index) => (
                      <MenuItem key={index} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TrackModelRequestTable;
