import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { History } from 'core';
import { Footer } from 'ui-kit';
import { Router as ReactRouter } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';

import { SET_LANGUAGE } from './localization/reducer';
import Header from './components/Header';
import Router from './components/Router';
import SidebarItems from 'components/SidebarItems';
import useLocal from 'localization';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: SET_LANGUAGE,
      lang: 'en'
    });
  }, [dispatch]);

  return (
    <React.Fragment>
      <CssBaseline />

      <Header headerTitle={useLocal('app_name')} />
      <Box minHeight="calc(100vh - 80px)" display="flex" width="100vw">
        <ReactRouter history={History}>
          <Box>
            <SidebarItems />
          </Box>
          <Box flexGrow={1} bgcolor="bgGray.main" padding={2}>
            <Box bgcolor="white.main" p={2} minHeight="calc(100vh - 110px)">
              <Router />
            </Box>
          </Box>
        </ReactRouter>
      </Box>
      <Footer />
    </React.Fragment>
  );
}

export default App;
