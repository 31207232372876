import actionTypes, { LoginPageState, actionCreatorTypes } from './types';

const initialState: LoginPageState = {
  isLoading: false,
  isError: false,
  erorr: null,
  data: {}
};

const reducer = (
  state: LoginPageState = initialState,
  action: actionCreatorTypes
): LoginPageState => {
  switch (action.type) {
    case actionTypes.LOGIN_REQUEST_PENDING:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.LOGIN_ERORR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        erorr: action?.error ?? null
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        erorr: null,
        data: action.payload ?? null
      };

    default:
      return state;
  }
};

export default reducer;
