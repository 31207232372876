import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  error: {
    background: theme.palette.error.light,
    color: theme.palette.error.dark
  },
  info: {
    background: theme.palette.primary.light,
    color: theme.palette.primary.dark
  },
  success: {
    background: theme.palette.success.light,
    color: theme.palette.success.dark
  },
  tableHeight: {
    maxHeight: 600
  },
  assignTH: {
    width: '15em'
  },
  statusTH: {
    width: '15em'
  }
}));
