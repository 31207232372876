import { createSelector } from 'reselect';

import * as LoginPageTypes from './types';
import { RootState } from 'store';
const getLoginState = (state: RootState): LoginPageTypes.LoginPageState => state.LoginPageReducer;
const hasError = createSelector(
  [getLoginState],
  (loginState: LoginPageTypes.LoginPageState): boolean => loginState.isError
);
const error = createSelector(
  [getLoginState],
  (loginState: LoginPageTypes.LoginPageState): string | null => loginState.erorr
);
const isLoading = createSelector(
  [getLoginState],
  (loginState: LoginPageTypes.LoginPageState): boolean => loginState.isLoading
);

export default {
  hasError,
  error,
  isLoading
};
