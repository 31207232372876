import actionTypes, {
  LoginRequestActionCreatorType,
  LoginPendingActionCreatorType,
  LoginErrorActionCreatorType,
  LoginSuccessActionCreatorType,
  LogoutActionCreatorType
} from './types';

const loginRequest = (
  email: string,
  password: string,
  redirectTo?: string | null
): LoginRequestActionCreatorType => ({
  type: actionTypes.LOGIN_REQUEST,
  email,
  password,
  redirectTo
});
const loginRequestPending = (): LoginPendingActionCreatorType => ({
  type: actionTypes.LOGIN_REQUEST_PENDING
});
const loginRequestErorr = (error: string): LoginErrorActionCreatorType => {
  return {
    type: actionTypes.LOGIN_ERORR,
    error
  };
};

const loginRequestSuccess = (
  token: string,
  refreshToken: string,
  email: string
): LoginSuccessActionCreatorType => ({
  type: actionTypes.LOGIN_SUCCESS,
  payload: {
    token,
    refreshToken,
    email
  }
});

const logout = (): LogoutActionCreatorType => ({
  type: actionTypes.LOGOUT
});

export default {
  loginRequest,
  loginRequestPending,
  loginRequestErorr,
  loginRequestSuccess,
  logout
};
