import React from 'react';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import useLocal from 'localization';
import useStyles from './style';
import { HeaderPropType } from './types';
import { actions as authAction } from 'pages/Login/duck';

const Header: React.FC<HeaderPropType> = ({ headerTitle }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const triggerAnchorEl = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box marginX={3}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        height="56px"
      >
        <Box className={classes.appTitle}>Daloopa - {headerTitle}</Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <AccountCircleIcon className={classes.accountIcon} onClick={triggerAnchorEl} />
          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={() => dispatch(authAction.logout())}>{useLocal('logout')}</MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
