import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  blueBtn: {
    marginBottom: theme.spacing(2),
    background: theme.palette.primary.light,
    color: theme.palette.primary.main,
    width: '100%',
    justifyContent: 'center',
    boxShadow: 'none',
    fontSize: '1.2em',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      justifyContent: 'center'
    },
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.white.main
    }
  }
}));
