import Routes from './routes';
import { AuthenticatedRoute, AuthenticatedRouteProps } from 'core';
import React from 'react';

export const DefaultAuthenticatedRoute = ({
  children,
  ...rest
}: Omit<AuthenticatedRouteProps, 'loginRoute'>) => (
  <AuthenticatedRoute loginRoute={Routes.LOGIN} {...rest}>
    {children}
  </AuthenticatedRoute>
);
