import React from 'react';
import useLocal from 'localization';
import Button from '@material-ui/core/Button';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import useStyles from './style';
import { TrackModelRequestTypes } from './duck';
import PageHeader from 'components/PageHeader';
import TrackModelRequestTable from 'components/TrackModelRequestTable';
import TablePaginationRow from 'components/TablePaginationRow';

const TrackModelRequest: React.FC<TrackModelRequestTypes.TrackModelRequestProps> = () => {
  const classes = useStyles();
  const totalRowPerPage = 10;
  const totalNumOfItems = 20;

  return (
    <div>
      <PageHeader title={useLocal('track_model_request')} groupText={useLocal('groups_text')}>
        <Button className={classes.blueBtn} startIcon={<PersonAddIcon />} variant="contained">
          {useLocal('link_name')}
        </Button>
      </PageHeader>
      <TrackModelRequestTable totalRowPerPage={totalRowPerPage} />
      <TablePaginationRow
        totalNumOfItems={totalNumOfItems}
        totalRowPerPage={totalRowPerPage}
        rowTitle={useLocal('row_title')}
      />
    </div>
  );
};

export default TrackModelRequest;
