import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { DaloopaTheme } from 'ui-kit';
import { ThemeProvider } from '@material-ui/core/styles';
import store from './store';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={DaloopaTheme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
