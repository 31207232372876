import React from 'react';
import { SidebarItem as SidebarItemType } from 'ui-kit';

import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { Sidebar } from 'ui-kit';
import routes from 'components/Router/routes';
import useLocal from 'localization';

import CreativeImg from 'assets/creative.png';

const SidebarItems: React.FC = () => {
  const Items: SidebarItemType[] = [
    {
      to: routes.GROUPS,
      title: useLocal('groups'),
      icon: <GroupIcon />
    },
    {
      to: routes.USERS,
      title: useLocal('users'),
      icon: <PersonIcon />
    },
    {
      to: routes.MODEL_REQUEST,
      title: useLocal('track_model_request'),
      icon: <AssignmentTurnedInIcon />
    }
  ];

  return <Sidebar items={Items} imagePath={CreativeImg} />;
};

export default SidebarItems;
