const actionTypes = {
  LOGIN_REQUEST: 'marketplace/LoginPage/LOGIN_REQUEST',
  LOGOUT: 'marketplace/LoginPage/LOGOUT',
  LOGIN_SUCCESS: 'marketplace/LoginPage/LOGIN_SUCCESS',
  LOGIN_ERORR: 'marketplace/LoginPage/LOGIN_ERORR',
  LOGIN_REQUEST_PENDING: 'marketplace/LoginPage/LOGIN_REQUEST_PENDING'
};

export const ACCESS_TOKEN_COOKIE_NAME = 'accessToken';
export const REFRESH_TOKEN_COOKIE_NAME = 'refreshToken';
export const MIXPANEL_TOKEN = '46c30ade1d77fa9bce319f2093f7f48a';
export const INTERCOM_APP_ID = 'bce2swx3';

export type LoginPageProps = {
  // Add Props type here
};
export type LoginPageState = {
  //Add reducer state type here
  isLoading: boolean;
  isError: boolean;
  erorr: string | null;
  data: {
    // add data types here
    refreshToken?: string;
    token?: string;
  } | null;
};
export type LoginRequestActionCreatorType = {
  type: typeof actionTypes.LOGIN_REQUEST;
  email: string;
  password: string;
  redirectTo?: string | null;
};
export type LoginPendingActionCreatorType = {
  type: typeof actionTypes.LOGIN_SUCCESS;
};

export type LoginErrorActionCreatorType = {
  type: typeof actionTypes.LOGIN_ERORR;
  error: string;
};

export type LoginSuccessActionCreatorType = {
  type: typeof actionTypes.LOGIN_SUCCESS;
  payload: {
    refreshToken: string;
    token: string;
    email: string;
  };
};

export type LoginResponseType = {
  data: {
    tokenAuth: {
      refreshToken: string;
      token: string;
    } | null;
  };
  errors:
    | [
        {
          message: string;
          locations: [];
          path: [];
        }
      ]
    | null;
};

export type LogoutActionCreatorType = {
  type: typeof actionTypes.LOGOUT;
};
export type loginFormField = {
  email: string;
  password: string;
  redirectTo?: string | null;
};

export type actionCreatorTypes = {
  type: string;
  payload?: {
    token: string;
    refreshToken: string;
    email: string;
  };
  loginFormField?: loginFormField;
  error?: string;
};

export default actionTypes;
