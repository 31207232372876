import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  h2: {
    fontSize: '1.7em',
    lineHeight: '1.5em',
    fontWeight: 500,
    margin: '0'
  },
  groupText: {
    fontSize: '0.9em',
    marginTop: 0,
    color: theme.palette.text.secondary,
    fontWeight: 500
  }
}));
