const GROUPS = '/groups';
const USERS = '/users';
const MODEL_REQUEST = '/model-request';
const HOME = '/';
const LOGIN = '/login';

/**
 * Error Pages
 */

const ERROR_404 = '/404';
const ERROR_403 = '/403';
const ERROR_401 = '/401';
const ERROR_500 = '/500';

export default {
  GROUPS,
  USERS,
  MODEL_REQUEST,
  HOME,
  LOGIN,
  ERROR_404,
  ERROR_403,
  ERROR_401,
  ERROR_500
};
