import React from 'react';

import Props from './types';
import useStyles from './style';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

const PageHeader: React.FC<Props> = ({ title, groupText, children }: Props) => {
  const classes = useStyles();

  return (
    <>
      <Container disableGutters maxWidth={false}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={5} md={10} key="page-title">
            <h2 className={classes.h2} data-testid="page-title">
              {title}
            </h2>
            <Typography className={classes.groupText}>{groupText}</Typography>
          </Grid>
          <Grid item xs={12} sm={7} md={2} key="action-box">
            {children && (
              <Box
                py={0}
                mt={{ xs: 0, sm: 1 }}
                mb={{ xs: 1, sm: 1 }}
                flexGrow={1}
                display="flex"
                justifyContent="flex-end"
              >
                {children}
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default PageHeader;
