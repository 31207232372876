import { put, takeLatest } from 'redux-saga/effects';
import { GraphQL, gql, History } from 'core';
import Cookies from 'js-cookie';
import actionTypes, {
  LoginRequestActionCreatorType,
  LoginResponseType,
  ACCESS_TOKEN_COOKIE_NAME,
  REFRESH_TOKEN_COOKIE_NAME
} from './types';
import actions from './actions';
import routes from 'components/Router/routes';
import Mixpanel from 'mixPanel';
import { logout as authLogout } from 'core/modules/auth.utils';

export function* logout() {
  Mixpanel.track('models:log_out');
  yield authLogout();
}

export function* loginRequest({ email, password, redirectTo }: LoginRequestActionCreatorType) {
  try {
    yield put(actions.loginRequestPending());
    const {
      data: { tokenAuth }
    }: LoginResponseType = yield GraphQL.mutate({
      mutation: gql`
        mutation tokenAuthMutation($email: String!, $password: String!) {
          tokenAuth(email: $email, password: $password) {
            token
            refreshToken
          }
        }
      `,
      variables: {
        email,
        password
      },
      fetchPolicy: 'no-cache'
    });

    if (tokenAuth?.token && tokenAuth?.refreshToken) {
      const accessToken = tokenAuth.token;
      const refreshToken = tokenAuth.refreshToken;
      Cookies.set(ACCESS_TOKEN_COOKIE_NAME, accessToken);
      Cookies.set(REFRESH_TOKEN_COOKIE_NAME, refreshToken);
      yield put(actions.loginRequestSuccess(accessToken, refreshToken, email));

      Mixpanel.identify(email);
      Mixpanel.people.set({
        $email: email,
        $last_login: new Date()
      });

      if (redirectTo) {
        History.push(redirectTo);
      } else {
        History.push(routes.GROUPS);
      }
    }
  } catch (error) {
    yield put(actions.loginRequestErorr((error as { message: string })?.message));
  }
}

export default function* watchIncrementAsync() {
  yield takeLatest(actionTypes.LOGIN_REQUEST, loginRequest);
  yield takeLatest(actionTypes.LOGOUT, logout);
}
