import mixpanel from 'mixpanel-browser';
mixpanel.init('46c30ade1d77fa9bce319f2093f7f48a');

const env_check = process.env.REACT_APP_ENABLE_MIXPANEL === 'true';

const Mixpanel = {
  identify: (id: string) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (env_check) mixpanel.alias(id);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  track: (name: string, props?: { [key: string]: any }) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props: { [key: string]: string | number | unknown }) => {
      if (env_check) mixpanel.people.set(props);
    }
  },
  getDistinctId: () => mixpanel.get_distinct_id()
};

export default Mixpanel;
