import React from 'react'; // ,{ useEffect }
// import { useSelector, useDispatch } from 'react-redux';

import useLocal from 'localization';

// import { GroupsSelectors } from './duck';
import { GroupsTypes } from './duck';
// import { actionTypes } from './duck';
// import useStyles from './style';

const Groups: React.FC<GroupsTypes.GroupsProps> = () => {
  // const classes = useStyles();
  // const dispatch = useDispatch();

  // const localizedText = useLocal('translation_key');

  // useEffect(() => {
  //   dispatch({
  //     type: actionTypes.TEST1_ACTION
  //   });

  //   return () => {
  //     dispatch({
  //       type: actionTypes.TEST2_ACTION
  //     });
  //   };
  // }, [dispatch]);

  return (
    <div>
      <h1>{useLocal('group_info')}</h1>
    </div>
  );
};

export default Groups;
