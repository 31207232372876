import { Theme, withStyles, makeStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

export const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

export const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
    '& > button': {
      borderRadius: '5px',
      height: '40px',
      minWidth: '10.375em'
    }
  }
}))(MuiDialogActions);

export default makeStyles(() => ({
  dialog: {
    '& .MuiPaper-root': {
      borderRadius: '10px'
    }
  },
  spinner: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f1f1f1d4',
    zIndex: 1000
  }
}));
